async function getUser () {
  // For local development, we cant reach out to the API since we're running on a different domain.
  if (window.location.hostname === 'minirack.io') {
    return {
      _id: '000000012e9bd02c84fc26a9',
      name: 'queicherius',
      email: 'queicherius@gmail.com'
    }
  }

  const response = await window.fetch(`https://api.gw2efficiency.com/user/status`, {
    credentials: 'include'
  })

  if (response.status === 403) {
    return false
  }

  return response.json()
}

export default getUser
