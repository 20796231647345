import React, { Component } from 'react'
import c from 'classnames'

class Question extends Component {
  constructor (props) {
    super(props)
    this.onKeyPress = this.onKeyPress.bind(this)
  }

  async onKeyPress (event) {
    const target = event.target

    if (event.key === 'Enter') {
      await this.props.onSubmit()
      target.focus()
    }
  }

  render () {
    let {
      sideBySide,
      stepInputs,
      id,
      question,
      answer,
      type,
      options,
      userAnswers,
      onChange,
      isSubmitting
    } = this.props
    type = type || 'text'

    return (
      <div
        className={c([
          'eff-border-all',
          'eff-border-color-grey-10',
          'eff-padding-all-xl',
          'eff-padding-bottom-m',
          'eff-margin-bottom-xl',
          { 'eff-display-flex': sideBySide }
        ])}
      >
        {/* Question text (HTML) */}
        {type === 'text' && (
          <div
            className='eff-font-weight-bold eff-margin-bottom-s'
            dangerouslySetInnerHTML={{ __html: question }}
          />
        )}

        {/* Answer input */}
        <div
          className={c([
            { 'eff-flex-grow-1': sideBySide },
            { 'eff-margin-left-xl': sideBySide }
          ])}
        >
          {!stepInputs && type === 'text' && (
            <input
              className='eff-Input eff-Input--large eff-width-100'
              type='text'
              value={userAnswers[id] || ''}
              onChange={(e) => onChange(id, e.target.value)}
              onKeyPress={this.onKeyPress}
              placeholder={answer}
              disabled={isSubmitting}
            />
          )}

          {stepInputs && type === 'text' && stepInputs.map((label, index) => (
            <div
              key={index}
              className={c([
                'eff-display-flex',
                'eff-align-items-center',
                'eff-margin-bottom-m'
              ])}
            >
              <div style={{ width: 100 }} className='eff-flex-shrink-0'>
                {label}:
              </div>

              <input
                className='eff-Input eff-Input--large eff-width-100'
                type='text'
                value={userAnswers[id + '---' + index] || ''}
                onChange={(e) => onChange(id + '---' + index, e.target.value)}
                onKeyPress={this.onKeyPress}
                placeholder={answer}
                disabled={isSubmitting}
              />
            </div>
          ))}

          {type === 'checkbox' && (
            <div className='eff-Checkbox eff-display-inline-flex eff-align-items-center'>
              <input
                id={id}
                type='checkbox'
                value={userAnswers[id] === 'yes'}
                onChange={(e) => onChange(id, e.target.checked ? 'yes' : 'no')}
              />

              <label htmlFor={id}>
                <div
                  className='eff-font-weight-bold eff-margin-left-xs'
                  dangerouslySetInnerHTML={{ __html: question }}
                />
              </label>
            </div>
          )}

          {type === 'radio' && (
            <div>
              <div
                className='eff-margin-bottom-xl'
                dangerouslySetInnerHTML={{ __html: question }}
              />

              {Object.keys(options).map((option, index) => (
                <label
                  key={option}
                  className={c([
                    'eff-display-flex',
                    'eff-align-items-center',
                    { 'eff-margin-bottom-m': index !== Object.keys(options).length - 1 },
                    'eff-border-all',
                    'eff-border-color-grey-10',
                    'eff-padding-all-m',
                    'eff-hover-cursor-pointer',
                    { 'eff-background-color-grey-05': userAnswers[id] === option }
                  ])}
                  htmlFor={id + option}
                >
                  <div className='eff-RadioButton'>
                    <input
                      id={id + option}
                      name={id}
                      type='radio'
                      value={option}
                      checked={userAnswers[id] === option}
                      onChange={(e) => e.target.checked && onChange(id, option)}
                    />

                    <label htmlFor={id + option} />
                  </div>

                  <div
                    className='eff-margin-left-m'
                    dangerouslySetInnerHTML={{ __html: options[option] }}
                  />
                </label>
              ))}
            </div>
          )}

          {/* ID */}
          <div className='eff-font-size-xs eff-color-white eff-text-align-right eff-margin-top-xs'>
            {id}
          </div>
        </div>
      </div>
    )
  }
}

export default Question
