import React, { Component } from 'react'
import changeNightmode from '../actions/changeNightmode'

class NightmodeToggle extends Component {
  constructor (props) {
    super(props)

    this.state = {
      nightmode: window.localStorage.getItem('gw2e.global.nightmode') === 'true'
    }

    this.onChange = this.onChange.bind(this)
  }

  onChange (e) {
    changeNightmode(e.target.checked)
    this.setState({ nightmode: e.target.checked })
  }

  render () {
    return (
      <div className='eff-Checkbox eff-display-inline-flex eff-align-items-center'>
        <input
          id='nightmode-toggle'
          type='checkbox'
          checked={this.state.nightmode}
          onChange={this.onChange}
        />

        <label htmlFor='nightmode-toggle'>
          Nightmode
        </label>
      </div>
    )
  }
}

export default NightmodeToggle
