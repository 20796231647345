import React from 'react'
import c from 'classnames'
import NightmodeToggle from './NightmodeToggle'

function Layout ({ id, title, description, children }) {
  return (
    <div className='eff-padding-horizontal-m'>
      <div
        className='eff-margin-horizontal-auto eff-margin-top-xxl eff-margin-bottom-xxxl lightbox'
        style={{ maxWidth: 780 }}
      >
        <div className='eff-display-flex eff-align-items-center eff-margin-bottom-s'>
          <div className='eff-font-size-xxxl eff-font-weight-bold eff-margin-right-auto'>
            {title}
          </div>

          <NightmodeToggle />
        </div>

        <div
          className={c([
            'eff-font-size-l',
            'eff-margin-bottom-xxl',
            'eff-text-align-justify',
            { 'eff-font-weight-bold': !['hello'].includes(id) }
          ])}
          dangerouslySetInnerHTML={{ __html: description }}
        />

        {children}
      </div>
    </div>
  )
}

export default Layout
