async function postAnswers (userId, stepId, answers) {
  await new Promise((resolve) => window.setTimeout(resolve, 250))

  answers = groupAnswersById(answers)

  const response = await window.fetch(`${process.env.PUBLIC_URL}/api/${userId}/${stepId}/submit-step`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(answers)
  })

  if (response.status === 429) {
    throw new Error('Whoa, slow down there! You have sent too many answers too quickly. Please wait a minute and try again.')
  }

  if (response.status !== 200) {
    throw new Error('Uh oh, something went wrong there. Please report this error: ' + response.status + ' / ' + await response.text())
  }

  const json = await response.json()
  return json.correct
}

// Adds multi-answer support by transforming the answers from
// e.g. "id---Head", "id---Chest" into one continuous answer for
// the id, joined by newlines.
function groupAnswersById (answers) {
  // Group the answers by the ID
  let transformedAnswers = {}
  Object.entries(answers).forEach(([key, answer]) => {
    const [id, index] = key.split('---')

    if (!index) {
      transformedAnswers[id] = answer
      return
    }

    if (!transformedAnswers[id]) {
      transformedAnswers[id] = []
    }

    // Note: We set the answer at the index because that way if the
    // user enters the last one first, it will still end up at the
    // end of the answers here.
    transformedAnswers[id][index] = answer
  })

  // Move the arrays for groups into one string with newlines
  Object.keys(transformedAnswers).forEach(key => {
    if (typeof transformedAnswers[key] === 'object') {
      transformedAnswers[key] = transformedAnswers[key].join('\n')
    }
  })

  return transformedAnswers
}

export default postAnswers
