function loadGuesses () {
  const storedGuesses = window.localStorage.getItem('answer-guesses')

  try {
    return JSON.parse(storedGuesses) || {}
  } catch (_) {
    // Ignore parse errors
  }

  return {}
}

export default loadGuesses
