function changeNightMode (active) {
  window.localStorage.setItem('gw2e.global.nightmode', JSON.stringify(active))
  injectNightMode()
}

function injectNightMode () {
  if (window.localStorage.getItem('gw2e.global.nightmode') === 'true') {
    loadNightMode()
  } else {
    unloadNightMode()
  }
}

function loadNightMode () {
  let link = document.createElement('link')
  link.id = 'nightmode'
  link.rel = 'stylesheet'
  link.type = 'text/css'
  link.href = process.env.PUBLIC_URL + '/nightmode.css'
  link.media = 'all'

  const head = document.getElementsByTagName('head')[0]
  head.appendChild(link)
}

function unloadNightMode () {
  const link = document.getElementById('nightmode')
  if (link) { link.remove() }
}

injectNightMode()

export default changeNightMode
