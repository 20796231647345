import React, { Component } from 'react'
import c from 'classnames'
import baguetteBox from 'baguettebox.js'
import getUser from '../actions/getUser'
import getStep from '../actions/getStep'
import postAnswers from '../actions/postAnswers'
import loadGuesses from '../actions/loadGuesses'
import saveGuesses from '../actions/saveGuesses'
import Question from './Question'
import Countdown from './Countdown'
import Layout from './Layout'
import resetProgress from '../actions/resetProgress'

class Application extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isLoading: true,
      errorNotLoggedIn: false,
      errorNoEmailAddress: false,
      user: null,
      answers: loadGuesses(),
      submitting: false,
      errorSubmit: false,
      error: null
    }

    this.onAnswerChange = this.onAnswerChange.bind(this)
    this.onAnswerSubmit = this.onAnswerSubmit.bind(this)
  }

  async componentDidMount () {
    await this.getUser()

    if (this.state.user) {
      await this.getStep()
    }
  }

  async getUser () {
    const user = await getUser()

    // The user is not logged into gw2efficiency
    if (!user || !user.name) {
      return this.setState({ errorNotLoggedIn: true })
    }

    // The user has no email address linked to their gw2efficiency account
    if (!user.email) {
      return this.setState({ errorNoEmailAddress: true })
    }

    // The user is valid, set into the state
    this.setState({ user })
  }

  async getStep () {
    // Load the current step the user is on
    let step
    try {
      step = await getStep(this.state.user.name)
    } catch (err) {
      return this.setState({ error: err.message })
    }

    this.setState({ isLoading: false, step })

    // Setup the Lightbox functionality
    baguetteBox.run('.lightbox')
  }

  onAnswerChange (id, answer) {
    this.setState(state => {
      const answers = Object.assign({}, state.answers, { [id]: answer })
      saveGuesses(answers)
      return { answers }
    })
  }

  async onAnswerSubmit () {
    this.setState({ submitting: true, error: null, errorSubmit: false })

    let isCorrect
    try {
      isCorrect = await postAnswers(this.state.user.name, this.state.step.id, this.state.answers)
    } catch (err) {
      return this.setState({ submitting: false, error: err.message })
    }

    if (!isCorrect) {
      return this.setState({ submitting: false, errorSubmit: true })
    }

    await this.getStep()
    window.scrollTo(0, 0)
    this.setState({ submitting: false, answers: {} })
  }

  async onResetClick (hasFinished) {
    const warningMessage = `Are you sure you want to reset your entire progress?`

    if (!hasFinished && !window.confirm(warningMessage)) {
      return
    }

    try {
      await resetProgress(this.state.user.name)
    } catch (err) {
      return this.setState({ error: err.message })
    }

    window.localStorage.removeItem('answer-guesses')
    await this.getStep()
    window.scrollTo(0, 0)
    this.setState({ error: null, errorSubmit: false, answers: {} })
  }

  renderNotActive () {
    return (
      <Layout
        title='The event has finished'
        description={`
          <div>
            <div class="eff-margin-bottom-m">Thank you to everyone who played, we hope you had fun!</div>
            <div>If you have been drawn as a winner we will contact you via the email you provided on your gw2efficiency account.</div>
          </div>
        `}
      />
    )
  }

  renderErrorNotLoggedIn () {
    return (
      <Layout
        title='Login required'
        description={`
          <div>
            <div>You have to be logged into gw2efficiency to take part in this event.</div>
            <div>After logging in, please refresh this page to continue.</div>
          </div>
        `}
      >
        <div>
          <a
            className='eff-Button eff-Button--primary eff-Button--large'
            href='https://gw2efficiency.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Login
          </a>
        </div>
      </Layout>
    )
  }

  renderErrorNoEmailAddress () {
    return (
      <Layout
        title='Email required'
        description={`
          <div>
            <div>Your gw2efficiency account needs to have a valid email to take part in this event.</div>
            <div>After updating your account, please refresh this page to continue.</div>
          </div>
        `}
      >
        <div>
          <a
            className='eff-Button eff-Button--primary eff-Button--large'
            href='https://gw2efficiency.com/user'
            target='_blank'
            rel='noopener noreferrer'
          >
            Update account
          </a>
        </div>
      </Layout>
    )
  }

  render () {
    const state = this.state

    if (new Date() > new Date('2023-04-10T16:00:00.000Z')) {
      return this.renderNotActive()
    }

    if (state.errorNotLoggedIn) {
      return this.renderErrorNotLoggedIn()
    }

    if (state.errorNoEmailAddress) {
      return this.renderErrorNoEmailAddress()
    }

    if (state.isLoading) {
      if (state.error) {
        return (
          <div className='eff-Alert eff-Alert--danger eff-margin-bottom-xl eff-text-align-center'>
            {state.error}
          </div>
        )
      }

      return <Layout title='Loading...' />
    }

    return (
      <Layout
        id={state.step.id}
        title={state.step.title}
        description={state.step.description}
      >
        {/* Questions & answer fields */}
        {state.step.questions && state.step.questions.map(question => (
          <Question
            key={question.id}
            {...question}
            sideBySide={state.step.questionAnswerSideBySide}
            stepInputs={state.step.questionInputs}
            userAnswers={state.answers}
            onChange={this.onAnswerChange}
            onSubmit={this.onAnswerSubmit}
            isSubmitting={state.submitting}
          />
        ))}

        {/* Error display if the submitted answers were incorrect */}
        {state.errorSubmit && (
          <div className='eff-Alert eff-Alert--danger eff-margin-bottom-xl eff-text-align-center'>
            One or more answers you have submitted were incorrect.
          </div>
        )}

        {/* Error display if the submit went wrong (e.g. ratelimit) */}
        {state.error && (
          <div className='eff-Alert eff-Alert--danger eff-margin-bottom-xl eff-text-align-center'>
            {state.error}
          </div>
        )}

        {/* Countdown for locked steps */}
        {state.step.isLocked && state.step.unlockTime && (
          <Countdown
            time={new Date(state.step.unlockTime)}
            onCountdownFinish={() => this.getStep()}
          />
        )}

        {/* Submit button */}
        {!state.step.isLocked && (
          <div>
            <div className='eff-display-flex'>
              <button
                className={c([
                  'eff-Button',
                  'eff-Button--primary',
                  'eff-Button--large',
                  'eff-width-100',
                  'eff-justify-content-center'
                ])}
                onClick={this.onAnswerSubmit}
                disabled={state.submitting}
              >
                {state.step.id === 'hello' ? 'Get started' : 'Submit answers'}
              </button>
            </div>

            {!['hello'].includes(state.step.id) && (
              <div className='eff-margin-top-m eff-color-grey-40 eff-text-align-center eff-margin-bottom-xxxl'>
                Uppercase/lowercase and special characters, e.g. apostrophes, do not matter in
                your answers.
              </div>
            )}
          </div>
        )}

        {/* Reset button */}
        {state.step.id !== 'hello' && (
          <div>
            <div className='eff-display-flex'>
              <button
                className={c([
                  'eff-Button',
                  { 'eff-Button--primary': state.step.id === 'congratulations' },
                  { 'eff-Button--secondary': state.step.id !== 'congratulations' },
                  'eff-Button--large',
                  { 'eff-Button--danger': state.step.id !== 'congratulations' },
                  'eff-width-100',
                  'eff-justify-content-center'
                ])}
                onClick={() => this.onResetClick(state.step.id === 'congratulations')}
                disabled={state.submitting}
              >
                {state.step.id === 'congratulations' ? 'That was fun, start over!' : 'Reset progress'}
              </button>
            </div>
          </div>
        )}
      </Layout>
    )
  }
}

export default Application
