import React, { Component } from 'react'

class Countdown extends Component {
  constructor (props) {
    super(props)
    this.state = { update: new Date() }
  }

  componentDidMount () {
    this.interval = window.setInterval(
      () => this.setState({ update: new Date() }),
      100
    )
  }

  componentWillUnmount () {
    window.clearInterval(this.interval)
  }

  renderTimeString (ms) {
    const padZeroes = (number) => number.toString().padStart(2, '0')

    // Reload the page if the countdown ended
    if (ms < 0) {
      this.props.onCountdownFinish()
      ms = 0
    }

    // Render XXh XXm XXs out of the seconds
    let seconds = Math.floor(ms / 1000)
    let minutes = Math.floor(seconds / 60)
    seconds = seconds % 60

    let hours = Math.floor(minutes / 60)
    minutes = minutes % 60

    return padZeroes(hours) + 'h ' + padZeroes(minutes) + 'm ' + padZeroes(seconds) + 's'
  }

  render () {
    const time = this.props.time

    return (
      <div>
        <div
          className='eff-font-weight-bold eff-margin-bottom-m'
          style={{ fontSize: 68 }}
        >
          {this.renderTimeString(time - this.state.update)}
        </div>
        <div style={{ fontSize: 12 }}>
          {time.toLocaleString()}
        </div>
      </div>
    )
  }
}

export default Countdown
